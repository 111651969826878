<template>
  <div class="container">
    <div class="header">
      <img src="./assets/logo.png" class="logo">
      <div class="header-text">
        <h1>MANI INNOVATIONS</h1>
        <h1>& NETWORK TECHNOLOGIES</h1>
      </div>
    </div>
    <p class="body-text">
      Mani Innovations & Network Technologies (Mint) is dedicated to unraveling the complexities of resource scarcity, focusing on three primary areas: financial stability, food accessibility, and energy availability. Leveraging our expertise in software solutions, we devise strategies that address these core issues, collaborating with organizations equally committed to these challenges.
    </p>
    <p class="body-text">
      If you are interested in understanding how we can help you, <a href="mailto:hello@mintservices.org?subject=Inquiry%20about%20Mani%20Innovations%20and%20Network%20Technologies&body=I'm%20interested%20in%20learning%20more%20about%20how%20your%20services%20can%20benefit%20my%20business.%20Please%20get%20in%20touch%20with%20me%20at%20your%20earliest%20convenience.">contact us here</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style scoped>
.container {
  padding: 40px;
  max-width: 50%;
  background-color: #F5EEE3;
}

.header {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 40px;
}

.logo {
  width: 100px;
  margin-right: 20px;
}

.header-text h1 {
  font-size: 36px;
  margin: 0;
  color: #3F3327;
}

.body-text {
  font-size: 14px;
  line-height: 1.5;
  width: 95%;
  font-family: 'Open Sans', sans-serif;
  color: #3e2a1c;
}

a {
  color: #333;
  text-decoration: underline;
}

@media (max-width: 960px) {
  .container {
    padding: 30px;
    max-width: 90%;
  }

  .header-text h1 {
    font-size: 28px;
  }

  .body-text {
    font-size: 13px;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 20px;
    max-width: 80%;
  }

  .header-text h1 {
    font-size: 20px;
  }

  .body-text {
    font-size: 12px;
    width: 100%;
  }
}
</style>